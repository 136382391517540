import {
  Button,
  Text,
  Stack
} from '@chakra-ui/react'
import styled from 'styled-components'
import { COLORS } from 'utils/styles'

export const ParentOfAll = styled(Stack)`
  margin-top: -5px;
`

export const ParentCustomScroller = styled(Stack)`
  width: 100%;
  align-items: center;
`

export const CustomScroller = styled(Stack)`
  height: 85px;
  border: solid 1px black;
  border-radius: 5px;
  background: ${COLORS.whiteBlackGradientTopToBot};
  padding: 30px 15px;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;
  background-color: #f9f9f9;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  gap: 0 !important;
  position: relative;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  transition: scroll-top 0.2s ease-out, box-shadow 0.3s ease-in;

  ::-webkit-scrollbar {
   display: none;
  }

  @media (max-width: 473px) {
    height: 75px;
    padding: 25px 15px;
  }

  @media (max-width: 380px) {
    height: 60px;
    padding: 20px 15px;
  }
`

export const CustomScrollerNumber = styled(Text)`
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: white;
  font-weight: bold;
  scroll-snap-align: center;

  @media (max-width: 800px) {
    height: 36px;
    font-size: 30px;
  }

  @media (max-width: 473px) {
    height: 33px;
    font-size: 25px;
  }

  @media (max-width: 380px) {
    height: 25px;
    font-size: 20px;
  }
`

export const CustomScrollerLabel = styled(Text)`
  // 
`

export const ButtonParent = styled(Stack)`
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 473px) {
    margin-top: 3px
  }

  @media (max-width: 380px) {
    margin-top: -3px
  }
`

export const CustomPill = styled(Button)`
  border-radius: 9999px !important;
  background-color: ${COLORS.gold} !important;
  padding: 6px 5px !important;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  width: 75px;
  height: auto !important;
  font-weight: 500 !important;
  font-size: 15px !important;

  @media (max-width: 473px) {
    font-size: 12px !important;
  }

  :hover {
    background: ${COLORS.gold_hover};
  }
`
