import {
  useEffect,
  useRef,
  useState
} from 'react'
import { Stack } from '@chakra-ui/react'
import { isMobile } from 'utils/helpers'
import { COLORS } from 'utils/styles'
import { PENDING } from 'utils/constants'
import { LoadingSpinier } from 'common/Loading'
import {
  ParentCustomScroller,
  CustomScroller,
  CustomScrollerNumber,
  CustomScrollerLabel,
  ButtonParent,
  CustomPill
} from './styled'

export const CustomDatePicker = ({
  setChooseDate,
  setPostSubmitType,
  setChosenDate,
  setPillHoverShow,
  setPillHoverText,
  postSubmitType,
  postPostsStatus,
  putPostsStatus,
  addImagePostsStatus,
  postCallOnGoing,
  isBodyEmpty,
  title,
  getPostsStatus,
  plannedAtOrig,
  activeScroller,
  setActiveScroller
}) => {
  const yearRef = useRef(null)
  const monthRef = useRef(null)
  const dayRef = useRef(null)
  const hourRef = useRef(null)
  const minRef = useRef(null)

  const [chosenYear, setChosenYear] = useState('')
  const [chosenMonth, setChosenMonth] = useState('')
  const [chosenDay, setChosenDay] = useState('')
  const [chosenHour, setChosenHour] = useState('')
  const [chosenMinute, setChosenMinute] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [activeRef, setActiveRef] = useState(null)
  const [yearBS, setYearBS] = useState('')
  const [monthBS, setMonthBS] = useState('')
  const [dayBS, setDayBS] = useState('')
  const [hourBS, setHourBS] = useState('')
  const [minBS, setMinBS] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)

  let scrollTimeout = null

  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear() % 100

    return Array.from({ length: 5 }, (_, i) => String(currentYear + i))
  }

  const getAvailableMonths = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1

    let startMonth = 1
    if (chosenYear === currentYear.toString()) {
      startMonth = currentMonth
    }

    return Array.from({ length: 12 - startMonth + 1 }, (_, i) => String(startMonth + i))
  }

  const getAvailableDays = () => {
    if (!chosenYear || !chosenMonth) return []

    const year = parseInt(chosenYear, 10)
    const monthIndex = parseInt(chosenMonth, 10) - 1

    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate()

    const currDate = new Date()
    const today = currDate.getDate()
    const isCurrentMonth = monthIndex === currDate.getMonth() && year === currDate.getFullYear()

    const startDay = isCurrentMonth ? today : 1

    return Array.from({ length: daysInMonth - startDay + 1 }, (_, i) => (startDay + i).toString())
  }

  const getAvailableHours = () => {
    const now = new Date()
    const currentHour = now.getHours()
    const currentMinute = now.getMinutes()
    const inputDate = new Date(chosenYear + '-' + chosenMonth + '-' + chosenDay)

    let startHour = 0
    if (
      now.getDate() === inputDate.getDate()
      && now.getMonth() === inputDate.getMonth()
      && now.getFullYear() === inputDate.getFullYear()
    ) {
      startHour = currentHour

      if (currentMinute >= 59) {
        startHour += 1
      }
    }

    return Array.from({ length: 24 - startHour }, (_, i) => (startHour + i).toString())
  }

  const getAvailableMinutes = () => {
    const now = new Date()
    const currentMinute = now.getMinutes()
    const inputDate = new Date(chosenYear + '-' + chosenMonth + '-' + chosenDay + ' ' + chosenHour + ':00:00')

    let startMinute = 0
    if (
      now.getDate() === inputDate.getDate()
      && now.getMonth() === inputDate.getMonth()
      && now.getFullYear() === inputDate.getFullYear()
      && String(now.getHours()) === chosenHour
    ) {
      startMinute = currentMinute + 1
    }

    return Array.from({ length: 60 - startMinute }, (_, i) => (startMinute + i).toString())
  }

  const handleScroll = (e, type) => {
    const container = e.target
    const items = Array.from(container.children)
    const containerTop = container.getBoundingClientRect().top
    const containerCenter = containerTop + container.offsetHeight / 2

    let closestItem = null
    let minDistance = Infinity

    items.forEach((item) => {
      const itemTop = item.getBoundingClientRect().top
      const distance = Math.abs(itemTop + item.offsetHeight / 2 - containerCenter)

      if (distance < minDistance) {
        minDistance = distance
        closestItem = item
      }
    })

    if (closestItem) {
      if (type === 'year') setChosenYear('20' + closestItem.getAttribute('data-year'))
      if (type === 'month') setChosenMonth(closestItem.getAttribute('data-month'))
      if (type === 'day') setChosenDay(closestItem.getAttribute('data-day'))
      if (type === 'hour') setChosenHour(closestItem.getAttribute('data-hour'))
      if (type === 'minute') setChosenMinute(closestItem.getAttribute('data-minute'))
    }
  }

  const scrollToChosen = (target) => {
    const targetElem = document.querySelector(target)

    if (targetElem) {
      // targetElem.parentNode.scrollTop = targetElem.offsetTop - 20
      targetElem.parentNode.scrollTo({
        top: targetElem.offsetTop - 20,
        behavior: 'smooth'
      })
    }
  }

  const changeMonthArr = () => {
    const monthArr = getAvailableMonths()
    const firstElem = monthArr[0].padStart(2, '0')

    let goToStart = false
    if (chosenMonth === '' || monthArr.indexOf(parseInt(chosenMonth, 10).toString()) === -1) {
      goToStart = true
      setChosenMonth(firstElem)
    }

    scrollToChosen(`p[data-month="${goToStart ? firstElem : chosenMonth}"]`)
  }

  const changeDayArr = () => {
    const dayArr = getAvailableDays()

    try {
      const firstElem = dayArr[0].padStart(2, '0')

      let goToStart = false
      if (chosenDay === '' || dayArr.indexOf(parseInt(chosenDay, 10).toString()) === -1) {
        goToStart = true
        setChosenDay(firstElem)
      }

      scrollToChosen(`p[data-day="${goToStart ? firstElem : chosenDay}"]`)
    } catch {
      //
    }
  }

  const changeHourArr = () => {
    const hourArr = getAvailableHours()
    const firstElem = hourArr[0].padStart(2, '0')

    let goToStart = false
    if (chosenHour === '' || hourArr.indexOf(parseInt(chosenHour, 10).toString()) === -1) {
      goToStart = true
      setChosenHour(firstElem)
    }

    scrollToChosen(`p[data-hour="${goToStart ? firstElem : chosenHour}"]`)
  }

  const changeMinArr = () => {
    const minuteArr = getAvailableMinutes()
    const firstElem = minuteArr[0].padStart(2, '0')

    let goToStart = false
    if (chosenMinute === '' || minuteArr.indexOf(parseInt(chosenMinute, 10).toString()) === -1) {
      goToStart = true
      setChosenMinute(firstElem)
    }

    scrollToChosen(`p[data-minute="${goToStart ? firstElem : chosenMinute}"]`)
  }

  const handleMouseDown = (e, ref) => {
    setIsDragging(true)
    setStartY(e.clientY)
    setScrollTop(ref.current.scrollTop)
    setActiveRef(ref)
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaY = e.clientY - startY
      const newScrollTop = scrollTop - deltaY

      requestAnimationFrame(() => {
        activeRef.current.scrollTop = newScrollTop
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    setActiveRef(null)
  }

  const handleKeyDown = (e) => {
    if (activeScroller) {
      const { type, ref } = activeScroller
      const items = Array.from(ref.current.children)

      const currentIndex = items.findIndex((item) => {
        if (type === 'year') return item.getAttribute('data-year') === chosenYear.split('20')[1]
        if (type === 'month') return item.getAttribute('data-month') === chosenMonth
        if (type === 'day') return item.getAttribute('data-day') === chosenDay
        if (type === 'hour') return item.getAttribute('data-hour') === chosenHour
        if (type === 'minute') return item.getAttribute('data-minute') === chosenMinute
        return false
      })

      let newIndex
      if (e.key === 'ArrowUp') newIndex = Math.max(currentIndex - 1, 0)
      else if (e.key === 'ArrowDown') newIndex = Math.min(currentIndex + 1, items.length - 1)
      else return

      if (newIndex !== currentIndex && newIndex >= 0 && newIndex < items.length) {
        const newItem = items[newIndex]
        const newValue = newItem.getAttribute(`data-${type}`)

        if (type === 'year') setChosenYear('20' + newValue)
        else if (type === 'month') setChosenMonth(newValue)
        else if (type === 'day') setChosenDay(newValue)
        else if (type === 'hour') setChosenHour(newValue)
        else if (type === 'minute') setChosenMinute(newValue)

        scrollToChosen(`p[data-${type}="${newValue}"]`)
      }
    }
  }

  const onScroll = (e, type) => {
    if (scrollTimeout) clearTimeout(scrollTimeout)

    scrollTimeout = setTimeout(() => {
      handleScroll(e, type)
    }, 200)
  }

  useEffect(() => {
    if (activeScroller) {
      const { type } = activeScroller

      if (
        type === 'year'
        && yearBS === ''
      ) {
        setYearBS('0 30px 10px rgba(0, 0, 0, 0.2) !important')
      } else if (
        type === 'year'
        && yearBS !== ''
      ) {
        setYearBS('')
        setActiveScroller(null)
      } else {
        setYearBS('')
      }

      if (
        type === 'month'
        && monthBS === ''
      ) {
        setMonthBS('0 30px 10px rgba(0, 0, 0, 0.2) !important')
      } else if (
        type === 'month'
        && monthBS !== ''
      ) {
        setMonthBS('')
        setActiveScroller(null)
      } else {
        setMonthBS('')
      }

      if (
        type === 'day'
        && dayBS === ''
      ) {
        setDayBS('0 30px 10px rgba(0, 0, 0, 0.2) !important')
      } else if (
        type === 'day'
        && dayBS !== ''
      ) {
        setDayBS('')
        setActiveScroller(null)
      } else {
        setDayBS('')
      }

      if (
        type === 'hour'
        && hourBS === ''
      ) {
        setHourBS('0 30px 10px rgba(0, 0, 0, 0.2) !important')
      } else if (
        type === 'hour'
        && hourBS !== ''
      ) {
        setHourBS('')
        setActiveScroller(null)
      } else {
        setHourBS('')
      }

      if (
        type === 'minute'
        && minBS === ''
      ) {
        setMinBS('0 30px 10px rgba(0, 0, 0, 0.2) !important')
      } else if (
        type === 'minute'
        && minBS !== ''
      ) {
        setMinBS('')
        setActiveScroller(null)
      } else {
        setMinBS('')
      }
    } else {
      setYearBS('')
      setMonthBS('')
      setDayBS('')
      setHourBS('')
      setMinBS('')
    }
  }, [activeScroller])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    activeScroller,
    chosenYear,
    chosenMonth,
    chosenDay,
    chosenHour,
    chosenMinute
  ])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])

  useEffect(() => {
    changeMonthArr()
    changeDayArr()
    changeHourArr()
    changeMinArr()
  }, [chosenYear])

  useEffect(() => {
    changeDayArr()
    changeHourArr()
    changeMinArr()
  }, [chosenMonth])

  useEffect(() => {
    changeHourArr()
    changeMinArr()
  }, [chosenDay])

  useEffect(() => {
    changeMinArr()
  }, [chosenHour])

  useEffect(() => {
    if (plannedAtOrig !== '') {
      const origDate = new Date(plannedAtOrig)
      const origYear = String(origDate.getFullYear())
      const origMonth = String(origDate.getMonth() + 1).padStart(2, '0')
      const origDay = String(origDate.getDate()).padStart(2, '0')
      const origHour = String(origDate.getHours()).padStart(2, '0')
      const origMinute = String(origDate.getMinutes()).padStart(2, '0')

      scrollToChosen(`p[data-year="${origYear.split('20')[1]}"]`)
      setChosenYear(origYear)

      setTimeout(() => {
        scrollToChosen(`p[data-month="${origMonth}"]`)
        setChosenMonth(origMonth)
      }, 300)

      setTimeout(() => {
        scrollToChosen(`p[data-day="${origDay}"]`)
        setChosenDay(origDay)
      }, 600)

      setTimeout(() => {
        scrollToChosen(`p[data-hour="${origHour}"]`)
        setChosenHour(origHour)
      }, 900)

      setTimeout(() => {
        scrollToChosen(`p[data-minute="${origMinute}"]`)
        setChosenMinute(origMinute)
      }, 1200)
    }
  }, [plannedAtOrig])

  useEffect(() => {
    setTimeout(() => setInitialLoad(false), 290)
  }, [])

  return (
    <Stack>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="100px"
        marginTop="8px"
        sx={{
          '@media (max-width: 473px)': {
            marginTop: '3px'
          },
          '@media (max-width: 380px)': {
            marginTop: '-7px'
          }
        }}
      >
        <ParentCustomScroller as="button">
          <CustomScroller
            ref={yearRef}
            onDoubleClick={() => setActiveScroller({ type: 'year', ref: yearRef })}
            onScroll={(e) => {
              if (initialLoad) {
                handleScroll(e, 'year')
              } else {
                onScroll(e, 'year')
              }
            }}
            onMouseDown={(e) => handleMouseDown(e, yearRef)}
            cursor={isDragging ? 'grabbing' : 'grab'}
            boxShadow={yearBS}
          >
            {
              getCurrentYear().map((year) => (
                <CustomScrollerNumber
                  data-year={year}
                  style={{
                    color: chosenYear !== '' && year === chosenYear.split('20')[1]
                      ? COLORS.gold
                      : ''
                  }}
                >
                  {year}
                </CustomScrollerNumber>
              ))
            }
          </CustomScroller>

          <CustomScrollerLabel>
            Year
          </CustomScrollerLabel>
        </ParentCustomScroller>

        <ParentCustomScroller as="button">
          <CustomScroller
            ref={monthRef}
            onDoubleClick={() => setActiveScroller({ type: 'month', ref: monthRef })}
            onScroll={(e) => {
              if (initialLoad) {
                handleScroll(e, 'month')
              } else {
                onScroll(e, 'month')
              }
            }}
            onMouseDown={(e) => handleMouseDown(e, monthRef)}
            cursor={isDragging ? 'grabbing' : 'grab'}
            boxShadow={monthBS}
          >
            {
              getAvailableMonths().map((month) => (
                <CustomScrollerNumber
                  data-month={month.padStart(2, '0')}
                  style={{
                    color: chosenMonth !== '' && month.padStart(2, '0') === chosenMonth
                      ? COLORS.gold
                      : ''
                  }}
                >
                  {month}
                </CustomScrollerNumber>
              ))
            }
          </CustomScroller>

          <CustomScrollerLabel>
            Month
          </CustomScrollerLabel>
        </ParentCustomScroller>

        <ParentCustomScroller as="button">
          <CustomScroller
            ref={dayRef}
            onDoubleClick={() => setActiveScroller({ type: 'day', ref: dayRef })}
            onScroll={(e) => {
              if (initialLoad) {
                handleScroll(e, 'day')
              } else {
                onScroll(e, 'day')
              }
            }}
            onMouseDown={(e) => handleMouseDown(e, dayRef)}
            cursor={isDragging ? 'grabbing' : 'grab'}
            boxShadow={dayBS}
          >
            {
              getAvailableDays().map((day) => (
                <CustomScrollerNumber
                  data-day={day.padStart(2, '0')}
                  style={{
                    color: chosenDay !== '' && day.padStart(2, '0') === chosenDay
                      ? COLORS.gold
                      : ''
                  }}
                >
                  {day}
                </CustomScrollerNumber>
              ))
            }
          </CustomScroller>

          <CustomScrollerLabel>
            Day
          </CustomScrollerLabel>
        </ParentCustomScroller>

        <ParentCustomScroller as="button">
          <CustomScroller
            ref={hourRef}
            onDoubleClick={() => setActiveScroller({ type: 'hour', ref: hourRef })}
            onScroll={(e) => {
              if (initialLoad) {
                handleScroll(e, 'hour')
              } else {
                onScroll(e, 'hour')
              }
            }}
            onMouseDown={(e) => handleMouseDown(e, hourRef)}
            cursor={isDragging ? 'grabbing' : 'grab'}
            boxShadow={hourBS}
          >
            {
              getAvailableHours().map((hour) => (
                <CustomScrollerNumber
                  data-hour={hour.padStart(2, '0')}
                  style={{
                    color: chosenHour !== '' && hour.padStart(2, '0') === chosenHour
                      ? COLORS.gold
                      : ''
                  }}
                >
                  {hour}
                </CustomScrollerNumber>
              ))
            }
          </CustomScroller>

          <CustomScrollerLabel>
            Hour
          </CustomScrollerLabel>
        </ParentCustomScroller>

        <ParentCustomScroller as="button">
          <CustomScroller
            ref={minRef}
            onDoubleClick={() => setActiveScroller({ type: 'minute', ref: minRef })}
            onScroll={(e) => {
              if (initialLoad) {
                handleScroll(e, 'minute')
              } else {
                onScroll(e, 'minute')
              }
            }}
            onMouseDown={(e) => handleMouseDown(e, minRef)}
            cursor={isDragging ? 'grabbing' : 'grab'}
            boxShadow={minBS}
          >
            {
              getAvailableMinutes().map((minute) => (
                <CustomScrollerNumber
                  data-minute={minute.padStart(2, '0')}
                  style={{
                    color: chosenMinute !== '' && minute.padStart(2, '0') === chosenMinute
                      ? COLORS.gold
                      : ''
                  }}
                >
                  {minute}
                </CustomScrollerNumber>
              ))
            }
          </CustomScroller>

          <CustomScrollerLabel>
            Minute
          </CustomScrollerLabel>
        </ParentCustomScroller>
      </Stack>

      <ButtonParent>
        <CustomPill
          pointerEvents={putPostsStatus !== PENDING && postPostsStatus !== PENDING ? 'auto' : 'none'}
          onMouseEnter={() => {
            if (!isMobile()) {
              setPillHoverText('Cancel scheduling for future release')
              setPillHoverShow(true)
            }
          }}
          onMouseLeave={() => {
            if (!isMobile()) {
              setPillHoverShow(false)
            }
          }}
          onTouchStart={() => {
            setPillHoverText('Cancel scheduling for future release')
            setPillHoverShow(true)
          }}
          onTouchEnd={() => {
            setPillHoverShow(false)
          }}
          onClick={() => setChooseDate(false)}
        >
          Cancel
        </CustomPill>

        <CustomPill
          pointerEvents={
            getPostsStatus !== PENDING
            && putPostsStatus !== PENDING
            && postPostsStatus !== PENDING
            && (
              isBodyEmpty()
              || title.trim() === ''
            )
              ? 'none'
              : 'auto'
          }
          backgroundColor={
            isBodyEmpty()
            || title.trim() === ''
              ? COLORS.gray_op8 + ' !important'
              : COLORS.gold + ' !important'
          }
          onMouseEnter={() => {
            if (!isMobile()) {
              setPillHoverText('Save in Scheduled tab for future release')
              setPillHoverShow(true)
            }
          }}
          onMouseLeave={() => {
            if (!isMobile()) {
              setPillHoverShow(false)
            }
          }}
          onTouchStart={() => {
            setPillHoverText('Save in Scheduled tab for future release')
            setPillHoverShow(true)
          }}
          onTouchEnd={() => {
            setPillHoverShow(false)
          }}
          onClick={() => {
            setChosenDate(chosenYear + '-' + chosenMonth + '-' + chosenDay + ' ' + chosenHour + ':' + chosenMinute + ':00')
            setPostSubmitType('plan')
          }}
        >
          {
            postSubmitType === 'plan'
            && (
              putPostsStatus === PENDING
              || postPostsStatus === PENDING
              || addImagePostsStatus === PENDING
              || postCallOnGoing
            )
              ? (
                <LoadingSpinier
                  width="15px"
                  height="15px"
                />
              ) : 'Save'
          }
        </CustomPill>
      </ButtonParent>
    </Stack>
  )
}
