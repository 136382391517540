import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@chakra-ui/react'
import { deleteFilter, usersSelectors } from 'store'
import { COLORS } from 'utils/styles'

import { FilterItem } from './FilterItem'
import { StyledFiltersContainer } from './styled'

export const FiltersData = () => {
  const { filters, isOpenRightModal } = useSelector(usersSelectors)
  const [isShow, setIsShow] = useState(false)
  const [animateToLeft, setAnimateToLeft] = useState(false)

  const dispatch = useDispatch()

  const deleteSearchBarFilter = (id) => {
    dispatch(deleteFilter(id))
    setIsShow(false)
  }

  useEffect(() => {
    // Used for the timing of animation to left relative to drawers/modals which opens from right
    setTimeout(() => setAnimateToLeft(isOpenRightModal), 175)
  }, [isOpenRightModal])

  return (
    <Stack className="filter-data">
      {isShow && (
        <Box
          textAlign="center"
          padding="10px 20px"
          width="200px"
          background={COLORS.gray_op8}
          zIndex={999}
          right="220px"
          top="22px"
          borderRadius="8px"
          position="absolute"
        >
          No affinity or code word found
        </Box>
      )}

      <StyledFiltersContainer isOpenRightModal={animateToLeft} name="affinities_list">
        {filters.map((item) => (
          <FilterItem
            setIsShow={setIsShow}
            deleteSearchBarFilter={deleteSearchBarFilter}
            item={item}
            key={item.id}
            length={filters?.length}
          />
        ))}
      </StyledFiltersContainer>
    </Stack>
  )
}
