import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { COLORS, FLEX_CONTAINER } from 'utils/styles'

export const StyledModalContainer = styled(motion.div)`
  ${FLEX_CONTAINER.FlexColumn}
  gap:16px;
  overflow: scroll;
  width: 365px;
  height: 85svh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar{
    display: none
  }
  
  @media (max-width: 480px) and (max-height: 800px) {
    width: 85svw;
  }

  @media (max-width: 450px) and (max-height: 800px) {
    width: 82svw;
  }

  @media (max-width: 390px) and  (max-height: 800px) {
    width: 330px;
  }
`

export const StyledUserProfileContainer = styled.div`
  ${FLEX_CONTAINER.FlexSpaceBetweenRow}
  align-items: center;
  position: relative;
  height: 95px;
`

export const StyledCopiedContainer = styled.div`
  width: max-content;
  padding: 3px 6px 0px;
  background-color: ${COLORS.gold};
  border-radius: 5px;
`

export const StyledCopyIcon = styled.div`
  cursor: pointer;
`

export const StyledUserName = styled.div`
  font-size: 16px;
  letter-spacing: 0.07em;
`

export const StyledUserLastAccessed = styled.div`
  font-size: 12px;
  letter-spacing: 0.07em;
  color: ${COLORS.darkGray};
`

export const StyledConnectionsContainer = styled.div`
  ${FLEX_CONTAINER.FlexSpaceBetweenRow}
`

export const StyledConnectionsContent = styled.div`
  ${FLEX_CONTAINER.FlexCenterColumn}
  align-items: center;
`

export const StyledContentTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${COLORS.darkGray};
`

export const StyledContentText = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${COLORS.black};
`

export const StyledContentImage = styled.img`
  width: 28px;
  height: 28px;
`

export const StyledWordsTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${COLORS.black};
  margin: 0 20px;
`

export const StyledFlexContainer = styled.div`
  ${FLEX_CONTAINER.FlexRow}
  column-gap: 10px;
  row-gap: 1rem;
  flex-wrap: wrap;
  margin: 0 20px;
`

export const StyledWordsContent = styled.div`
  padding: 6px 24px;
  background: ${COLORS.semiLightGray};
  border-radius: 50px;
`
