import styled from 'styled-components/macro'
import { DrawerContent } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FLEX_CONTAINER } from 'utils/styles'

const DrawerMotion = motion(DrawerContent)

export const StyledDrawerContent = styled(DrawerMotion)`
  ${FLEX_CONTAINER.FlexColumn}
  gap:16px;
  overflow: scroll;
  width: 365px;
  height: 88svh;
  margin-inline: auto;
  margin-block: 60px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar{
    display: none
  }
`
