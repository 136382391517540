import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getComments,
  likePost,
  setBlogParent,
  setComments,
  setUserSectionPage
} from 'store'
import {
  EditIcon2,
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import {
  PENDING,
  PROFILE_BLOG_COMMENT
} from 'utils/constants'
import { motion } from 'framer-motion'
import { isNotMobile, postTypeStatus } from 'utils/helpers'
import { LikeButtonAnimation } from './styled'
import { TextEditorReader } from './TextEditorReader'

const MotionStack = motion(Stack)
const MotionBtn = motion(Button)

export const BlogList = ({
  fetchPosts,
  profilePicture,
  fullName,
  posts,
  setEditPostData,
  getPostsStatus,
  postsTypeStatus
}) => {
  const dispatch = useDispatch()
  const [hideText, setHideText] = useState([])
  const [animate, setAnimate] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [oldPostsCount, setOldPostsCount] = useState(0)
  const observer = useRef()
  const secondToLastItem = useRef(null)

  const animateLike = (id) => {
    setAnimate((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))

    setTimeout(() => {
      setAnimate((prevState) => ({
        ...prevState,
        [id]: !prevState[id]
      }))
    }, 500)
  }

  const hideTextMap = (id) => {
    setHideText((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const colorDirection = () => {
    console.log()
    return isNotMobile()
      ? COLORS.lightGoldGradientRightToLeft
      : COLORS.lightGoldGradientLeftToRight
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      // The "10" is the limit set on the backend DB query
      if (entries[0].isIntersecting && posts.length - oldPostsCount >= 10) {
        observer.current.unobserve(secondToLastItem.current)
        setLoadingMore(true)
        setOldPostsCount(posts.length)
        fetchPosts(posts.at(-1).id)
      }
    })

    const currentObserver = observer.current
    if (secondToLastItem.current && posts.length - oldPostsCount >= 10) {
      currentObserver.observe(secondToLastItem.current)
    }

    setLoadingMore(false)

    return () => {
      if (secondToLastItem.current) {
        observer.current.disconnect()
      }
    }
  }, [posts])

  return (
    <Stack
      margin="16px 0"
      gap="16px 0 !important"
    >
      {
        getPostsStatus === PENDING && !posts.length
          ? (
            <Stack marginTop="100px">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
      {
        posts && posts.length
          ? posts.map((item, index) => (
            <Stack
              ref={posts.length - 2 === index ? secondToLastItem : null}
              backgroundColor="rgb(229, 229, 229)"
              width="calc(100% + 112px)"
              borderRadius="3px"
              border="solid 1px black"
              padding="10px"
              display={
                (
                  postsTypeStatus === 'scheduled'
                  && postTypeStatus(item).includes('Scheduled')
                ) || (
                  postsTypeStatus === 'posted'
                  && (
                    postTypeStatus(item).includes('Published')
                    || postTypeStatus(item).includes('Modified')
                  )
                ) || (
                  postsTypeStatus === 'archived'
                  && postTypeStatus(item).includes('Archived')
                )
                  ? 'flex'
                  : 'none'
              }
              justifyContent="flex-start"
              marginLeft="-56px"
            >
              <Stack
                width="15px"
                height="15px"
                marginLeft="auto"
                cursor="pointer"
                onClick={() => {
                  setEditPostData({})
                  setEditPostData(item)
                }}
              >
                <EditIcon2 />
              </Stack>

              <Stack
                alignItems="center"
                display="inline"
                width="auto"
                justifyContent="flex-start"
                marginLeft="auto"
                marginRight="auto"
                sx={{
                  '@media (max-width: 473px)': {
                    marginTop: '10px'
                  }
                }}
              >
                <Image
                  boxSize="55px"
                  position="relative"
                  objectFit="cover"
                  src={profilePicture || ImagesPng.DefaultImage}
                  alt="User Image"
                  borderRadius="full"
                  display="inline-flex"
                  verticalAlign="bottom"
                  sx={{
                    '@media (max-width: 473px)': {
                      boxSize: '50px'
                    }
                  }}
                />
                <Text
                  display="inline-flex"
                  marginLeft="10px"
                  fontSize="16px"
                  lineHeight="28px"
                  wordBreak="break-word"
                  sx={{
                    '@media (max-width: 380px)': {
                      fontSize: '14px'
                    }
                  }}
                >
                  {fullName}
                  <br />
                  { postTypeStatus(item) }
                </Text>
              </Stack>

              <Stack
                display="flex"
                justifyContent="center"
                direction="row"
              >
                <MotionBtn
                  initial={{
                    background: hideText[item.id]
                      ? colorDirection()
                      : COLORS.darkGrayGradientBotToTop
                  }}
                  animate={{
                    background: hideText[item.id]
                      ? COLORS.darkGrayGradientBotToTop
                      : colorDirection()
                  }}
                  whileTap={{ scale: 0.8 }}
                  border="solid 1px rgb(119, 119, 119)"
                  padding="2px 30px"
                  fontSize="19px"
                  width="95%"
                  borderRadius="5px"
                  align="center"
                  letterSpacing=".15px"
                  fontWeight="400"
                  _hover={{
                    background: ''
                  }}
                  _active={{
                    background: ''
                  }}
                  onClick={() => hideTextMap(item.id)}
                  display="block"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {item.title}
                </MotionBtn>
              </Stack>

              <MotionStack
                initial={{ x: 0 }}
                animate={{
                  height: hideText[item.id] ? '0px' : 'auto',
                  opacity: hideText[item.id] ? '0' : '1'
                }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 60
                }}
                direction="row"
                justifyContent="center"
                margin="-2.5px 0"
                display="flex"
              >
                {
                  item.affinities.length
                    ? item.affinities.map((e) => (
                      <Box
                        color="#F8F8FF"
                        border="solid 1px black"
                        borderRadius="5px"
                        bg={COLORS.gold}
                        p="0 2px"
                        data-name="blog-affinity"
                        fontSize="11px"
                        margin="2.5px 0"
                        overflow="hidden"
                      >
                        {e.title}
                      </Box>
                    ))
                    : (
                      <Box
                        color="#F8F8FF"
                        border="solid 1px black"
                        borderRadius="5px"
                        bg={COLORS.gold}
                        p="0 2px"
                        data-name="blog-affinity"
                        fontSize="11px"
                        overflow="hidden"
                      >
                        No Affinities
                      </Box>
                    )
                }
              </MotionStack>

              <MotionStack
                initial={{ x: 0 }}
                animate={{
                  height: hideText[item.id] ? '0px' : 'auto',
                  opacity: hideText[item.id] ? '0' : '1'
                }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 60
                }}
                display="block"
                overflow="hidden"
              >
                <TextEditorReader item={item} />
              </MotionStack>

              <Stack
                direction="row"
                borderTop="solid 1px black"
                width="calc(100% + 20px)"
                padding="3.5px 0px"
                justifyContent="space-around"
                marginBottom="-10px"
                marginLeft="-10px"
              >
                <LikeButtonAnimation className={animate[item.id] ? 'animate' : ''}>
                  <Stack
                    as="button"
                    onClick={() => {
                      animateLike(item.id)
                      dispatch(likePost(item.id))
                    }}
                  >
                    <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                  </Stack>

                  <Text>{item.likes}</Text>
                </LikeButtonAnimation>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Stack
                    as="button"
                    onClick={() => {
                      dispatch(setComments([]))
                      dispatch(setBlogParent([item]))
                      dispatch(getComments({
                        type: 'post',
                        post_id: item.id
                      }))
                      dispatch(setUserSectionPage(PROFILE_BLOG_COMMENT))
                    }}
                  >
                    <CommentIcon />
                  </Stack>
                  <Text>{item.comments}</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <RepostIcon />
                  <Text>0</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <ShareIcon />
                  <Text>0</Text>
                </Stack>
              </Stack>
            </Stack>
          ))
          : (
            <Stack>
              {
                getPostsStatus === PENDING && !posts.length
                  ? ''
                  : (
                    <Text
                      textAlign="center"
                      width="calc(100% + 112px)"
                      backgroundColor="rgb(229, 229, 229)"
                      borderRadius="3px"
                      border="solid 1px black"
                      padding="10px"
                      margin="0 0 0 -56px !important"
                    >
                      Click the "Create" button to create a post
                    </Text>
                  )
              }
            </Stack>
          )
      }
      {
        loadingMore && getPostsStatus === PENDING
          ? (
            <Stack margin="75px 0 50px 0">
              <LoadingSpinier />
            </Stack>
          ) : ''
      }
    </Stack>
  )
}
