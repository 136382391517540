import {
  useEffect, useRef, useState
} from 'react'
import { Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Center,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import {
  ArrowIcon,
  ResizeIcon,
  ShrinkIcon
} from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'
import { LoadingSpinier } from 'common/Loading'
import {
  addFriend,
  deleteFriend,
  getNotificationMessage,
  getPosts,
  getComments,
  setComments,
  setBlogParent,
  usersSelectors,
  setOpenPopupBlogs
} from 'store'
import { openConnectedProfileChat, setForceCloseChatPanel } from 'store/chat/slice'
import {
  IS_FRIEND_STATUSES,
  PENDING,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'
import {
  isMobile,
  translateFromLocalUrl,
  statusMarkerColor
} from 'utils/helpers'
import {
  StyledFlexContainer,
  StyledModalContainer,
  StyledUserProfileContainer,
  StyledWordsContent,
  StyledWordsTitle
} from './styled'
import { MarkerBlogPane } from './MarkerBlogPane'

const ctaButtonTextObj = {
  connect: 'Connect',
  contact: 'Contact',
  pending: 'Pending',
  delete: 'Delete'
}

export const MarkerPopup = ({
  selectedMarkerData,
  markerPopupCoords,
  handleClosePopup
}) => {
  const dispatch = useDispatch()

  const popupRef = useRef(null)

  const {
    activeProfile,
    blogParent,
    comments,
    commentsOption,
    getCommentsStatus,
    getPostsStatus,
    postCommentsStatus,
    putCommentsStatus,
    posts,
    profileItem,
    openPopupBlogs,
    subscribeToProfileStatus
  } = useSelector(usersSelectors)

  const [blogSection, setBlogSection] = useState('posts')
  const dataLink = translateFromLocalUrl()
  const [isCopiedLink, setIsCopiedLink] = useState(false)
  const [copyTextWarning, setCopyTextWarning] = useState('Share')
  const [pay, setPay] = useState('Pay')
  const [ctaButtonText, setCtaButtonText] = useState(ctaButtonTextObj.connect)
  const [showBlogPane, setShowBlogPane] = useState(false)
  const [widen, setWiden] = useState(false)
  const [widenAnimation, setWidenAnimation] = useState(false)
  const [blogPaneAnimation, setBlogPaneAnimation] = useState(false)
  const [outAnimation, setOutAnimation] = useState(false)

  const validDate = dayjs(selectedMarkerData?.last_accessed)
  const iconMarker = statusMarkerColor(validDate)

  const shouldWiden = () => (widen) && (!isMobile() || window.innerWidth > 700)
  const getPopupHeight = () => (window.innerHeight - 150) + 'px'

  const handleCtaClick = () => {
    if (profileItem.is_friends === IS_FRIEND_STATUSES.accepted) {
      dispatch(openConnectedProfileChat(profileItem))
    } else if (profileItem.is_friends === IS_FRIEND_STATUSES.no) {
      if (activeProfile?.id) {
        setTimeout(() => dispatch(addFriend(profileItem?.id)), 500)
        setTimeout(() => {
          setCtaButtonText(ctaButtonTextObj.pending)
        }, 2500)
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'You do not have an active profile. Please setup your profile first.'
          })
        )
      }
    }
  }

  // To be hidden until further instructions
  const handleRemoveConnection = () => {
    // todo move this fucntionality for delete
    setTimeout(() => dispatch(deleteFriend(profileItem?.id)), 1000)
    // close Chat pahhel if it is opened
    // to prevent user sending messages after unfriending
    dispatch(setForceCloseChatPanel(true))
  }

  const handlePay = () => {
    setPay('Coming Soon')
    setTimeout(() => setPay('Pay'), 2000)
  }

  const handleShare = () => {
    setIsCopiedLink(true)
    setCopyTextWarning('COPIED')
    navigator.clipboard.writeText(translateFromLocalUrl())
    dispatch(
      getNotificationMessage({
        type: SUCCEEDED,
        message: 'Copied'
      })
    )
  }

  const handleBlogBackBtn = () => {
    if (blogParent.length > 1) {
      if (getCommentsStatus === PENDING) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'Please finish loading first before returning to previous blog post or comment',
          isEditProfile: true
        }))
      } else {
        const parentData = blogParent[blogParent.length - 1]

        dispatch(setComments([]))
        dispatch(getComments({
          type: parentData.parent_type,
          ...(parentData.parent_type === 'post' ? { post_id: parentData.post_id } : { comment_id: parentData.parent_comment_id })
        }))

        dispatch(setBlogParent(blogParent.slice(0, -1)))
      }
    } else {
      setBlogSection('posts')
    }
  }

  useEffect(() => {
    if (isCopiedLink) {
      const clearSuccess = setTimeout(() => {
        setCopyTextWarning('Share')
        setIsCopiedLink(false)
      }, 1000)

      return () => {
        clearTimeout(clearSuccess)
      }
    }
    return undefined
  }, [isCopiedLink])

  useEffect(() => {
    if (profileItem?.is_friends === IS_FRIEND_STATUSES.accepted) {
      setCtaButtonText(ctaButtonTextObj.contact)
    } else if (profileItem?.is_friends === IS_FRIEND_STATUSES.pending) {
      setCtaButtonText(ctaButtonTextObj.pending)
    }

    if (profileItem !== null) {
      dispatch(getPosts({
        type: 'marker',
        id: selectedMarkerData.id
      }))
    }
  }, [profileItem])

  useEffect(() => {
    setOutAnimation(false)

    if (openPopupBlogs) {
      setShowBlogPane(true)
      dispatch(setOpenPopupBlogs(false))
    }
  }, [])

  return (
    <Stack
      id="custom-popup-overlay"
      width="100%"
      height="100%"
      onClick={() => {
        setOutAnimation(true)
        setTimeout(() => handleClosePopup(), 300)
      }}
    >
      <Popup
        maxWidth={5000}
        minWidth={300}
        closeButton={false}
        closeOnClick={false}
        offset={[0, (-window.innerHeight - 720) / 100]}
        ref={popupRef}
        position={markerPopupCoords}
        autoClose
      >
        {profileItem === null ? (
          <StyledModalContainer
            style={{
              height: 'auto',
              opacity: outAnimation ? 0 : 1
            }}
          >
            <Box width="100%" height="170px">
              <LoadingSpinier />
            </Box>
          </StyledModalContainer>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: getPopupHeight(),
              overflowY: 'clip',
              position: 'relative'
            }}
          >
            <StyledModalContainer
              initial={{
                y: outAnimation ? 0 : window.innerHeight
              }}
              animate={{
                y: outAnimation ? window.innerHeight : 0
              }}
              transition={{
                type: 'tween',
                duration: 0.3
              }}
              onClick={(e) => e.stopPropagation()}
              style={{
                height: getPopupHeight(),
                position: 'absolute',
                visibility: widenAnimation ? 'hidden' : 'visible'
              }}
            >
              <StyledUserProfileContainer>
                <Box
                  position="absolute"
                  width="100%"
                  height="95px"
                  zIndex={1}
                  backgroundImage={profileItem?.banner || ImagesPng?.DefaultImage}
                  bgPosition="center"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  borderTopRadius={10}
                  left={0}
                  top={0}
                  paddingX={10}
                />
                <Grid
                  width="100%"
                  height="80px"
                  marginX={5}
                  marginY={2}
                  templateRows="repeat(2, 1fr)"
                  templateColumns="repeat(6, 1fr)"
                  zIndex={2}
                  gap={2.5}
                >
                  <GridItem colSpan={2} rowSpan={2} width="86px">
                    <Center>
                      <Image
                        boxSize="70px"
                        objectFit="cover"
                        src={profileItem?.profile_picture || ImagesPng.DefaultImage}
                        alt="image"
                        borderRadius="full"
                      />
                    </Center>
                  </GridItem>
                  <GridItem
                    colSpan={4}
                    rowSpan={1}
                    width="100%"
                  >
                    {profileItem?.is_my_profile ? null : (
                      <Stack
                        direction="row"
                        position="relative"
                        marginLeft={profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '0px' : '29%'}
                        height="50px"
                        sx={{
                          '@media (max-width: 480px)': {
                            marginLeft: profileItem?.is_friends === IS_FRIEND_STATUSES.accepted ? '10px' : '40%',
                            float: 'right'
                          }
                        }}
                      >
                        <Stack direction="row" height="30px">
                          <SingleButton
                            text={copyTextWarning}
                            dataLink={dataLink}
                            click={handleShare}
                            name="share"
                            className="cardButton share"
                            customStyled={{
                              width: '65px'
                            }}
                          />
                          <SingleButton
                            click={handleCtaClick}
                            name="connect-status"
                            text={ctaButtonText}
                            className="cardButton"
                          />
                          {
                            profileItem?.is_friends === IS_FRIEND_STATUSES.accepted
                              ? (
                                <SingleButton
                                  className="cardButton"
                                  customStyled={{
                                    backgroundColor: COLORS.gray
                                  }}
                                  click={handleRemoveConnection}
                                  text={ctaButtonTextObj.delete}
                                  name="delete-connect"
                                />
                              )
                              : null
                          }
                        </Stack>
                      </Stack>
                    )}
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={4} width="100%">
                    <Stack position="relative" direction="column" spacing="4px">
                      <Stack alignItems="center" direction="row" spacing="10px">
                        <Text
                          fontSize="16px"
                          backgroundColor="white"
                          p="5px"
                          position="absolute"
                          borderRadius={3}
                          width="100%"
                          data-name="profileCardName"
                          sx={{
                            '@media (max-width: 375px)': {
                              // width: '170px',
                              'word-break': 'break-all'
                            }
                          }}
                        >
                          {profileItem?.full_name}
                        </Text>
                      </Stack>
                    </Stack>
                  </GridItem>
                </Grid>
              </StyledUserProfileContainer>

              {
                profileItem?.personal_quote ? (
                  <Stack direction="column" fontSize={15} marginX={20}>
                    <Text
                      as="span"
                      align="center"
                      sx={{
                        '::-webkit-line-clamp': '3',
                        'line-clamp': '3'
                      }}
                    >
                      {profileItem?.personal_quote}
                    </Text>
                  </Stack>
                ) : ''
              }

              <Stack textAlign="center" direction="column" gap="1rem" justifyContent="space-between" paddingX="20px" fontSize={15}>
                <Stack justifyContent="space-between" direction="row">
                  <Text as="span" fontWeight="bold">Connections</Text>
                  <Text as="span">
                    {profileItem?.active_connections}
                  </Text>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Text as="span" fontWeight="bold">Disconnections</Text>
                  <Text as="span">
                    {profileItem?.past_connections}
                    %
                  </Text>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Text as="span" fontWeight="bold">Status</Text>
                  <Text as="span">
                    {iconMarker}
                  </Text>
                </Stack>
              </Stack>

              <StyledWordsTitle>Affinities</StyledWordsTitle>

              <StyledFlexContainer>
                {profileItem?.affinities?.map((aff) => (
                  <StyledWordsContent key={uuid()}>{aff.title}</StyledWordsContent>
                ))}
              </StyledFlexContainer>

              {profileItem?.social_links ? (
                <>
                  <StyledWordsTitle>Social Profiles</StyledWordsTitle>

                  <Stack direction="column" marginX={5}>
                    <Stack direction="column">
                      {profileItem.social_links?.length > 0 ? (
                        <Stack direction="row" spacing="10px">
                          <Stack gap="1rem 10px" spacing="unset" flexWrap="wrap" direction="row">
                            {profileItem.social_links.map((item) => (
                              <Box
                                key={uuid()}
                                borderRadius="full"
                                bg={COLORS.goldGradient}
                                p="6px 10px"
                              >
                                <Link href={item.link} target="_blank" name={item.name} _cursor="pointer" style={{ textDecoration: 'none', color: 'black' }}>
                                  {item.name}
                                </Link>
                              </Box>
                            ))}
                          </Stack>
                        </Stack>
                      ) : (
                        <Text as="span" color={COLORS.gray}>No data available</Text>
                      )}
                    </Stack>
                  </Stack>
                </>
              ) : null}

              <StyledButtonContainer
                onClick={() => {
                  setShowBlogPane(true)
                  setBlogPaneAnimation(true)
                }}
                name="Open blog pane"
                style={{
                  margin: '30px 20px 0px',
                  padding: '7.5px 0px',
                  fontWeight: '500',
                  background: COLORS.gold
                }}
              >
                Blog
              </StyledButtonContainer>

              <StyledButtonContainer
                onClick={() => handlePay()}
                name="pay"
                style={{
                  margin: '0px 20px 10px',
                  padding: '7.5px 0px',
                  fontWeight: '500',
                  backgroundColor: '#e6e6e6'
                }}
              >
                {pay}
              </StyledButtonContainer>
            </StyledModalContainer>

            <StyledModalContainer
              initial={{
                ...(!blogPaneAnimation && { y: 1000 }),
                ...(blogPaneAnimation && { y: showBlogPane ? 500 : '' }),
                ...(outAnimation && showBlogPane && {
                  y: 0,
                  opacity: 1
                })
              }}
              animate={{
                ...(blogPaneAnimation && { y: showBlogPane ? 0 : '' }),
                width: shouldWiden() ? '70vw' : '',
                ...(outAnimation && showBlogPane && {
                  y: window.innerHeight,
                  opacity: 0
                })
              }}
              transition={{
                type: shouldWiden() || widenAnimation ? 'spring' : 'tween',
                ...(shouldWiden() || widenAnimation
                  ? {
                    stiffness: 190,
                    damping: 22
                  }
                  : { duration: 0.3 }
                )
              }}
              onClick={(e) => e.stopPropagation()}
              style={{
                height: getPopupHeight(),
                position: 'relative',
                ...(showBlogPane && { zIndex: 3 })
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                p="0px 5px"
                background={COLORS.goldGradientRightToLeft}
                zIndex={5}
                height="48px"
                borderRadius="10px 10px 0 0"
              >
                <IconButton
                  onClick={() => {
                    setWidenAnimation(true)
                    setWiden(!widen)
                  }}
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  icon={<ResizeIcon />}
                  id="resize-button"
                  outline="none"
                  boxShadow="none"
                  display={widen ? 'none' : 'flex'}
                  css={{
                    ':focus': {
                      outline: 'none',
                      boxShadow: 'none'
                    }
                  }}
                />
                <IconButton
                  onClick={() => setWiden(!widen)}
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  icon={<ShrinkIcon />}
                  id="shrink-button"
                  outline="none"
                  boxShadow="none"
                  display={widen ? 'auto' : 'none'}
                  css={{
                    ':focus': {
                      outline: 'none',
                      boxShadow: 'none'
                    }
                  }}
                />

                <Text
                  maxWidth="70%"
                  fontSize="24px"
                  textAlign="center"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {profileItem.full_name + "'s blog"}
                </Text>

                <IconButton
                  onClick={() => {
                    if (showBlogPane && blogSection === 'posts') {
                      setWidenAnimation(false)
                      setShowBlogPane(false)
                      setWiden(false)
                      setBlogPaneAnimation(false)

                      setTimeout(() => {
                        setBlogPaneAnimation(false)
                      }, 300)
                    } else {
                      handleBlogBackBtn()
                    }
                  }}
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  transform={blogSection === 'comments' ? 'rotate(180deg)' : 'rotate(-90deg)'}
                  icon={<ArrowIcon height="15px" />}
                  id="back-button"
                  outline="none"
                  boxShadow="none"
                  css={{
                    ':focus': {
                      outline: 'none',
                      boxShadow: 'none'
                    }
                  }}
                />
              </Stack>

              <MarkerBlogPane
                activeProfile={activeProfile}
                blogParent={blogParent}
                blogSection={blogSection}
                commentsList={comments}
                commentsOption={commentsOption}
                getCommentsStatus={getCommentsStatus}
                postCommentsStatus={postCommentsStatus}
                putCommentsStatus={putCommentsStatus}
                getPostsStatus={getPostsStatus}
                posts={posts}
                profileItem={profileItem}
                subscribeToProfileStatus={subscribeToProfileStatus}
                setBlogSection={setBlogSection}
                widen={widen}
              />
            </StyledModalContainer>
          </Box>
        )}
      </Popup>
    </Stack>
  )
}
