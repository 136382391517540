import axios from 'axios'
import { Buffer } from 'buffer'
import { API } from 'configs/api'
/* eslint-disable import/no-cycle */
import { store, xmppUpdateFriendLocation } from 'store'
import {
  setXmppPushNotification,
  showModalSpinner,
  hideModalSpinner,
  beginMobileImagePicker,
  endMobileImagePicker,
  beginMobileImageTransfer,
  endMobileImageTransfer,
  setXmppChatPanelOpened,
  setMeXmppOnlineStatus
} from 'store/chat/slice'
/* eslint-enable import/no-cycle */
import { getFriendsNotifications } from 'store/users/axios'
import './styles/custom.scss'

const MSG_UPDATE_PROFILE = 'update'
const MSG_REMOVE_PROFILE = 'remove'
const NOTIFY_TYPE_PROFILE = 'profile'
const NOTIFY_TYPE_FRIEND_REQ = 'new_friend_request'
const NOTIFY_TYPE_PUSH_NOTIFICATION = 'pushnotification'
const NOTIFY_TYPE_GEO_LOCATION = 'geo_location'

const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
}

const CustomizationPlugin = {
  initialize() {
    // NOTE: for message passing from within the plugin we use DOM listeners
    // https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
    // because it is not possible to use react-redux mechanism inside the plugin

    /* eslint-disable no-underscore-dangle */
    const convrs = this._converse
    /* eslint-enable no-underscore-dangle */
    const { api } = convrs
    // convrs.log.setLogLevel('debug')

    convrs.api.utils = {
      http_get_image_base64: async (profileId, type) => {
        try {
          const response = await axios.post(
            API.profileImage,
            { profileId, imageType: type },
            { token: true, responseType: 'arraybuffer' }
          )
          return Buffer.from(response.data, 'binary').toString('base64')
        } catch (err) {
          // image is optional so missing image is not an error
          return null
        }
      },
      closeChatPanel: async () => {
        await Promise.all(
          convrs.chatboxviews.map(async (view) => {
            let chatBox = document.querySelector('#chat-components')
            if (chatBox) {
              let chatComponents = chatBox.querySelectorAll('.in-animation')

              chatComponents.forEach((e) => {
                e.classList.remove('in-animation')
                e.classList.add('out-animation')
              })
            }

            await new Promise((resolve) => {
              setTimeout(() => {
                view.close()
                resolve()
              }, 300)
            })
          })
        )
        await store.dispatch(setXmppChatPanelOpened(false))
        return {}
      },
      notifyPartiesPEP: async (type, cmd, uname, message = '') => {
        const state = store.getState()
        if (!state.chat.meXmppIsOnline) {
          api.utils.internal_data.waitForNotifyPartiesPEP.push([type, cmd, uname, message])
          console.log('PEP, waiting for online')
          return {}
        }
        // construct PEP event https://xmpp.org/extensions/xep-0163.html for
        // user activity https://xmpp.org/extensions/xep-0108.html
        // adapted to transfer user profile change information
        // ---------------------------------------------------------------
        // <iq type="set" id="pub1">
        //   <pubsub xmlns="http://jabber.org/protocol/pubsub">
        //     <publish node='http://jabber.org/protocol/activity'>
        //       <item>
        //         <activity xmlns='http://jabber.org/protocol/activity'>
        //           <other>
        //             <nickname>test</nickname>
        //             <profile>update</profile>  <!-- or remove -->
        //           </other>
        //           <text xml:lang='en'>MESSAGE</text>
        //         </activity>
        //       </item>
        //     </publish>
        //   </pubsub>
        // </iq>
        //
        // converse.js/src/headless/shared/constants.js
        // strophe.js/src/builder.js
        /* eslint-disable no-undef */
        const name = !uname || uname.length === 0 ? 'admin' : uname
        const iq = new Strophe.Builder('iq', {
          type: 'set',
          from: convrs.jid
        }).c('pubsub', { xmlns: Strophe.NS.PUBSUB })
          .c('publish', { node: 'http://jabber.org/protocol/activity' })
          .c('item')
          .c('activity', { xmlns: 'http://jabber.org/protocol/activity' })
          .c('other')
          .c('nickname')
          .t(name)
          .up()
          .c(type)
          .t(cmd)
          .up()
          .up()
          .c('text', { 'xml:lang': 'en' })
          .t(message)
        return api.sendIQ(iq)
      },
      handling_dead_contact_probe: async (iq) => {
        // dead contact probe returns 'subscription-required' error:
        // <iq to="481929-0@xmpp.connector.app/ESP09211881401"
        //    from="511497-0@xmpp.connector.app" xml:lang="en" type="error" id="aadca">
        //   <query xmlns="http://jabber.org/protocol/disco#info"/>
        //     <error type="auth" code="407">
        //       <subscription-required xmlns="urn:ietf:params:xml:ns:xmpp-stanzas"/>
        //       <text xmlns="urn:ietf:params:xml:ns:xmpp-stanzas" xml:lang="en">
        //          Not subscribed
        //       </text>
        //     </error>
        // </iq>
        try {
          // eslint-disable-next-line no-undef
          const from = Strophe.getBareJidFromJid(iq.getAttribute('from'))
          if (iq.querySelector('error')?.getAttribute('type') === 'auth'
          && iq.querySelector('subscription-required') !== null) {
            convrs.roster.remove(from)
          }
        } catch (err) {
          console.error(err)
        }
        return Promise.resolve({})
      },
      dead_contact_probe: (jid) => {
        // sending dead contact probe
        // <iq to="511497-0@xmpp.connector.app" type="get" id="aadda">
        //   <query xmlns="http://jabber.org/protocol/disco#items"/>
        // </iq>
        /* eslint-disable no-undef */
        const iq = new Strophe.Builder('iq', {
          type: 'get',
          to: jid
        }).c('query', { xmlns: Strophe.NS.DISCO_ITEMS })
        return api.sendIQ(iq)
      },
      update_remote_contact: (jid, nickname) => {
        const contact = convrs.roster.models.find((e) => e.id === jid)
        if (contact === undefined) {
          return contact
        }
        contact.set('nickname', nickname)
        const vcard = convrs.vcards.models.find((e) => e.id === jid)
        vcard?.set('nickname', nickname)
        return contact
      },
      update_remote_avatar: async (model, type = '') => {
        const elem = model
        const profileId = elem.id.split('-')[0]
        // get avatar
        if (type === 'image' || type === '') {
          const avatar = await convrs.api.utils.http_get_image_base64(profileId, 'profile_picture')
          if (avatar !== null) {
            elem.set('image', avatar)
            elem.set('image_type', 'image/png')
            // avatar gets overwritten in vcard constructor which happens in network disruption
            elem.set('image_bak', avatar)
            elem.set('image_type_bak', 'image/png')
            if (model.vcard !== undefined) {
              model.vcard.set('image', avatar)
              model.vcard.set('image_type', 'image/png')
            }
          }
          elem.set('noCustomImage', avatar !== null)
          elem.set('triedToFetchCustomImage', Math.floor(Date.now() / 1000))
        }
        if (type === 'cover_image' || type === '') {
          const banner = await convrs.api.utils.http_get_image_base64(profileId, 'banner')
          if (banner !== null) {
            elem.set('cover_image', banner)
            elem.set('cover_image_type', 'image/png')
            if (model.vcard !== undefined) {
              model.vcard.set('cover_image', banner)
              model.vcard.set('cover_image_type', 'image/png')
            }
          }
          elem.set('noCustomCoverImage', banner !== null)
          elem.set('triedToFetchCustomCoverImage', Math.floor(Date.now() / 1000))
        }
        return elem
      },
      // get last message from archive: https://xmpp.org/extensions/xep-0313.html#filter
      // 1) query request contains messages in chronological order
      // 2) we can receive service messages without body that are not conversational
      // that is why we set max 10 even we need only one the latest conversational msg
      // 3) A message in a *USER'S* archive matches if the JID
      // matches either the to or from of the message.
      fetch_last_messages: (jid) => api.archive.query({ before: '', max: 10, with: jid })
        .then((result) => {
          const msgs = result.messages.map((stanza) => {
            const msg = stanza.querySelector('message')
            const serverStoringTime = stanza.querySelector('delay')
            const body = msg.querySelector('body')?.textContent?.trim()
            /* eslint-disable no-undef */
            const from = Strophe.getBareJidFromJid(msg.getAttribute('from'))
            const to = Strophe.getBareJidFromJid(msg.getAttribute('to'))
            /* eslint-enable no-undef */
            const timestamp = serverStoringTime.getAttribute('stamp')
            return {
              from, to, body, timestamp
            }
          }).filter((msg) => msg.body !== undefined)
          /* eslint-disable arrow-body-style */
          const orderred = msgs.map((e, index) => { return { order: index, ...e } })
          /* eslint-enable arrow-body-style */
          const dictFrom = orderred.reduce((acc, obj) => {
            const key = obj.from
            if (!acc[key]) {
              acc[key] = []
            }
            acc[key].push(obj)
            return acc
          }, {})
          const dictTo = orderred.reduce((acc, obj) => {
            const key = obj.to
            if (!acc[key]) {
              acc[key] = []
            }
            acc[key].push(obj)
            return acc
          }, {})
          convrs.roster.models.forEach((e) => {
            let arrFrom = dictFrom[e.get('jid')]
            let arrTo = dictTo[e.get('jid')]
            let arr
            if (arrFrom !== undefined && arrTo === undefined) {
              arr = [...arrFrom]
            } else if (arrFrom === undefined && arrTo !== undefined) {
              arr = [...arrTo]
            } else if (arrFrom !== undefined && arrTo !== undefined) {
              arr = [...arrFrom, ...arrTo]
            }

            if (arr !== undefined && arr.length > 0) {
              const sortedValues = arr.toSorted((a, b) => a.order - b.order)
              const [lastMsg] = sortedValues.slice(-1)
              const is_me = lastMsg.from === convrs.bare_jid
              const prefix = is_me ? 'ME: ' : ''
              e.set('most_recent_message', `${prefix}${lastMsg.body}`)
              e.set('most_recent_message_timestamp', lastMsg.timestamp)
            }
          })
          return msgs
        }),

      update_contact_last_message: (chat) => {
        if (chat === undefined || chat === null
          || chat.contact === undefined || chat.contact === null
          || chat.messages === undefined || chat.messages === null) {
          return
        }
        const [msg] = chat.messages.slice(-1)
        if (msg !== undefined && msg !== null) {
          const sender = msg.get('sender')
          const text = msg.getMessageText()
          if (!text) {
            return
          }
          if (text.endsWith('is online')) {
            // skip servicing messages like : 'user is online', etc
            return
          }
          chat.contact.set('most_recent_message', (sender === 'them') ? text : `${sender}: ${text}`)
          chat.contact.set('most_recent_message_timestamp', msg.get('time'))
        }
      },
      update_local_avatar: (model) => {
        if (model === undefined) {
          return Promise.resolve({})
        }
        const state = store.getState()
        const currUser = state.users.activeProfile
        const { full_name, profile_picture } = currUser
        /* eslint-disable no-useless-escape */
        // eslint-disable-next-line no-unused-vars
        const [_, imgType, imgBody] = profile_picture.split(/data:|base64\,/)
        /* eslint-disable no-useless-escape */
        model.set('nickname', full_name)
        model.set('image', imgBody)
        model.set('image_type', imgType)
        if (model.vcard !== undefined) {
          model.vcard.set('nickname', full_name)
          model.vcard.set('image', imgBody)
          model.vcard.set('image_type', imgType)
        }
        // do not store image on XMPP server
        return api.vcard.set(model.get('jid'), { fn: full_name, nickname: full_name, image: '<![CDATA[]]>' })
          .then(() => {
            // restoring image locally
            model.set('image', imgBody)
            model.set('image_type', imgType)
          })
      },
      notify_unread: () => {
        // Taiga #995: For chat counter to only look for num_unread from friends
        const chats = convrs.roster.models
        const num_unread = chats.reduce((acc, chat) => acc + (chat.get('num_unread') || 0), 0)
        const event = new CustomEvent('custom-chat-num_unread', {
          detail: {
            num_unread
          }
        })
        window.dispatchEvent(event)
      },
      notify_xmpp_account_problem: () => {
        const event = new CustomEvent('custom-chat-xmpp_account_problem', {
          detail: {
            ...api.utils.internal_data.ui.connection
          }
        })
        window.dispatchEvent(event)
      },

      onHeadlineMessage: async (msg) => {
        const keepRegistered = true
        /* eslint-disable no-undef */
        const from = Strophe.getBareJidFromJid(msg.getAttribute('from'))
        const to = Strophe.getBareJidFromJid(msg.getAttribute('to'))
        /* eslint-enable no-undef */
        if (msg.querySelector('event')?.getAttribute('xmlns') !== 'http://jabber.org/protocol/pubsub#event'
        || msg.querySelector('activity')?.getAttribute('xmlns') !== 'http://jabber.org/protocol/activity') {
          return keepRegistered
        }

        const isBareJidToFromEqual = (to === from)
        const isToFromEqual = (msg.getAttribute('from') === msg.getAttribute('to'))
        const fromNickname = msg.querySelector('nickname')?.textContent ?? 'admin'
        const message = msg.querySelector('text')?.textContent

        if (isBareJidToFromEqual === false
          && msg.querySelector(NOTIFY_TYPE_PROFILE)?.textContent === MSG_UPDATE_PROFILE) {
          const nickname = msg.querySelector('nickname').textContent
          const contact = convrs.api.utils.update_remote_contact(from, nickname)
          if (contact !== undefined) {
            await convrs.api.utils.update_remote_avatar(contact)
          }
        } else if (isBareJidToFromEqual === false
          && msg.querySelector(NOTIFY_TYPE_PROFILE)?.textContent === MSG_REMOVE_PROFILE) {
          await convrs.chatboxviews.map((view) => view.close())
          convrs.roster.remove(from)
        } else if (isBareJidToFromEqual === false
          && msg.querySelector(NOTIFY_TYPE_GEO_LOCATION) !== null) {
          const geoStr = msg.querySelector(NOTIFY_TYPE_GEO_LOCATION)?.textContent
          if (geoStr !== undefined && geoStr !== null) {
            const geo = JSON.parse(geoStr)
            // TODO: from
            // from : "501167-0@dat01.turnkeywebstores.biz"
            // fromNickname : "Live location enabled"
            // geo: { "lat": 60.2029599, "lng": 24.6540796 }
            const friendLocation = {
              from,
              fromNickname,
              geo
            }

            if (
              localStorage.getItem('id') === null
              && (
                localStorage.getItem('isNotifOpen') === null
                || localStorage.getItem('isNotifOpen') === 'false'
              )
            ) {
              await store.dispatch(xmppUpdateFriendLocation(friendLocation))
            }
          }
        } else if (isToFromEqual === true
          && msg.querySelector(NOTIFY_TYPE_FRIEND_REQ) !== null) {
          // NOTE: this method is a backend fetching event trigger, it does not need to convey data
          await store.dispatch(getFriendsNotifications())
          console.log('PUSH received new friend notification')
        } else if (isToFromEqual === true
          && msg.querySelector(NOTIFY_TYPE_PUSH_NOTIFICATION) !== null) {
          if (message === null || message === undefined || message.length === 0) {
            console.error('push notification without message body. Nothing to show.')
          } else {
            await store.dispatch(setXmppPushNotification(`${fromNickname}: ${message}`))
            console.log(`PUSH received from: ${fromNickname} , message: ${message}`)
          }
        }
        return keepRegistered
      },

      registerHeadlineHandler: () => {
        // for addHandler function parameters see 'strophe.js/src/connection.js': L987
        convrs.connection.addHandler(
          (m) => (convrs.api.utils.onHeadlineMessage(m) || true),
          'jabber:client',
          'message',
          'headline'
        )
      },

      updateImageOnDom: (model, type = '') => {
        const origModel = JSON.parse(JSON.stringify(model))
        const el = document.querySelector('converse-chat-heading')

        convrs.api.utils.update_remote_avatar(model, type).then((updatedModel) => {
          if (type === '' || type === 'image') {
            const rosterViewPic = document.querySelector(`converse-avatar[data-nickname="${origModel?.nickname}"] img`)
            if (rosterViewPic) {
              rosterViewPic.src = `data:${updatedModel.attributes.image_type};base64,${updatedModel.attributes.image}`
            }

            const chatViewPic = document.querySelector(`converse-avatar[data-nickname="${origModel?.nickname}"] svg image`)
            if (chatViewPic) {
              chatViewPic.setAttribute('href', `data:${updatedModel.attributes.image_type};base64,${updatedModel.attributes.image}`)
            }

            const chatPics = document.querySelectorAll(`image[data-nickname="${origModel?.nickname}"]`)
            if (chatPics.length) {
              chatPics.forEach((pic) => pic.setAttribute('href', `data:${updatedModel.attributes.image_type};base64,${updatedModel.attributes.image}`))
            }
          }

          if (
            (type === '' || type === 'cover_image')
            && el?.jid === updatedModel?.id
          ) {
            el.style.backgroundSize = 'cover'
            el.style.backgroundImage = `
              url('data:${updatedModel.attributes.cover_image_type};base64,${updatedModel.attributes.cover_image}')
            `
          }
        })
      },

      internal_data: {
        ui: {
          connection: {
            status: undefined,
            message: undefined
          }
        },
        waitForNotifyPartiesPEP: []
      }
    }

    api.waitUntil('chatBoxesInitialized').then(() => {
      // this is entry point right after converse has been initialized
      convrs.chatboxes.on('change:num_unread', convrs.api.utils.notify_unread)
    })

    api.waitUntil('rosterContactsFetched').then(async () => {
      if (convrs.roster.models.length === 0) {
        // ensure that roster is fetched
        await convrs.roster.fetchFromServer()
      }
      // NOTE: convrs.roster.models does not contain vcard
      const me = convrs.vcards?.models.find((model) => convrs.bare_jid === model.get('jid'))
      if (me !== undefined) {
        api.utils.update_local_avatar(me)
      }
      // NOTE: convrs.roster.models does not contain vcard
      const promiseArr = convrs.roster.models.map(async (model) => {
        // fetching nickname and then avatar
        try {
          await convrs.api.utils.dead_contact_probe(model.id)
          // NOTE: vcard.update with model.id ignores force update
          await api.vcard.update(model, true)
          model.vcard.set('nickname', model.get('nickname'))
          // const lastMsg = model.get('most_recent_message')
          // NOTE: model is not persisted browser storage
          // we have to read archive every time the application starts
          await convrs.api.utils.fetch_last_messages(model.id)
          return model
        } catch (err) {
          return convrs.api.utils.handling_dead_contact_probe(err)
        }
      })

      try {
        await Promise.allSettled(promiseArr)
        await store.dispatch(hideModalSpinner())
        return convrs.api.utils.notify_unread()
      } catch (err) {
        return console.error(err)
      }
    })

    if (!window.hostToPluginListener) {
      window.addEventListener('custom-chat-update_gps_coordinates', async () => {
        const state = store.getState()
        const liveCoordinates = state.chat.xmppLiveLocation
        const isLocationValid = !Number.isNaN(liveCoordinates.lat)
          && !Number.isNaN(liveCoordinates.lng)
        if (!isLocationValid) {
          return
        }
        const currUser = state.users.activeProfile
        if (!currUser) {
          return
        }
        const { full_name } = currUser
        try {
          const msg = JSON.stringify(liveCoordinates)
          await api.utils.notifyPartiesPEP(NOTIFY_TYPE_GEO_LOCATION, msg, full_name)
        } catch (err) {
          console.error(err)
        }
      })
      window.addEventListener('custom-chat-close_chat_panel', async () => {
        await convrs.api.utils.closeChatPanel()
      })
      window.addEventListener('custom-chat-open_chat_with_friend', (e) => {
        document.getElementsByClassName('chakra-image')[0].click()

        const jid = e.detail.recipient.id + '-0@' + process.env.REACT_APP_XMPP_CHAT_URL
        const userData = convrs.vcards?.models.find((model) => jid === model.get('jid'))
        const force = true

        api.chats.open(
          jid,
          userData?.attributes ?? {},
          force
        ).then(() => Promise.resolve('ok'))
      })
      window.addEventListener('custom-chat-avatar_change', async () => {
        // NOTE: convrs.roster does not contain 'ME'
        const me = convrs.vcards?.models.find((model) => convrs.bare_jid === model.get('jid'))
        if (me === undefined) {
          // this is the case when user account has been just created
          return
        }
        const state = store.getState()
        const currUser = state.users.activeProfile
        const { full_name } = currUser

        try {
          await api.utils.update_local_avatar(me)
          await api.utils.notifyPartiesPEP(NOTIFY_TYPE_PROFILE, MSG_UPDATE_PROFILE, full_name)
        } catch (err) {
          console.error(err)
        }
      })
      window.addEventListener('custom-chat-logout', () => {
        const event = new CustomEvent('custom-chat-logout-complete')
        if (api.connection.connected()) {
          api.user.logout()
            .then(() => store.dispatch(showModalSpinner()))
            .then(() => {
              window.dispatchEvent(event)
              sessionStorage.clear()
              return Promise.resolve({})
            })
            .catch((err) => {
              console.error(err)
            })
        } else {
          window.dispatchEvent(event)
        }
      })
      window.addEventListener('custom-chat-profile-removed', async () => {
        const event = new CustomEvent('custom-chat-profile-removed-notified')
        await api.utils.notifyPartiesPEP(NOTIFY_TYPE_PROFILE, MSG_REMOVE_PROFILE, null)
        window.dispatchEvent(event)
      })
      window.addEventListener('custom-chat-reopen_after_filesent', async () => {
        await store.dispatch(showModalSpinner())
        await store.dispatch(beginMobileImagePicker())
      })
      window.addEventListener('fetch-profile-image', (rosterList) => {
        console.log(rosterList)
        rosterList.detail.roster.forEach((r) => convrs.api.utils.updateImageOnDom(r, 'image'))
      })
      window.addEventListener('one-on-one', (d) => {
        const model = convrs.roster.models.find((e) => e.id === d.detail.roster.id)
        const el = document.querySelector('converse-chat-heading')

        if (model && model?.attributes?.noCustomCoverImage === undefined) {
          let type = 'cover_image'
          if (model?.attributes?.noCustomImage === undefined) {
            type = ''
          }

          convrs.api.utils.updateImageOnDom(model, type)
        } else if (el?.jid === model?.id) {
          const observer = new MutationObserver((mutationsList, ob) => {
            for (let mutation of mutationsList) {
              if (mutation.type === 'childList' && document.querySelector(`converse-avatar[data-nickname="${model.attributes.nickname}"] svg image`)) {
                const chatViewPic = document.querySelector(`converse-avatar[data-nickname="${model.attributes.nickname}"] svg image`)

                if (
                  model?.attributes?.image_type === 'image/svg+xml'
                  && model?.attributes?.image_bak !== undefined
                  && model?.attributes?.image_type_bak !== 'image/svg+xml'
                ) {
                  chatViewPic.setAttribute('href', `data:${model.attributes.image_type_bak};base64,${model.attributes.image_bak}`)
                } else {
                  chatViewPic.setAttribute('href', `data:${model.attributes.image_type};base64,${model.attributes.image}`)
                }

                ob.disconnect()
              }
            }
          })
          observer.observe(document.body, { childList: true, subtree: true })

          const chatPics = document.querySelectorAll(`image[data-nickname="${model.attributes.nickname}"]`)
          if (chatPics.length) {
            chatPics.forEach((pic) => pic.setAttribute('href', `data:${model.attributes.image_type};base64,${model.attributes.image}`))
          }

          el.style.backgroundSize = 'cover'
          el.style.backgroundImage = `
            url('data:${model.attributes.cover_image_type};base64,${model.attributes.cover_image}')
          `
        }
      })

      window.hostToPluginListener = true
    }

    api.listen.on('connfeedback', (arg) => {
      /* eslint-disable no-undef */
      if (api.utils.internal_data.ui.connection.status === Strophe.Status.AUTHFAIL) {
        /* eslint-enable no-undef */
        // never overwrite status after invalid XMPP account discovered
        return
      }
      api.utils.internal_data.ui.connection.status = arg.get('connection_status')
      api.utils.internal_data.ui.connection.message = arg.get('message')
      /* eslint-disable no-undef */
      if (api.utils.internal_data.ui.connection.status === Strophe.Status.AUTHFAIL) {
        /* eslint-enable no-undef */
        api.utils.notify_xmpp_account_problem()
      }
    })
    api.listen.on('connected', async () => {
      convrs.api.utils.registerHeadlineHandler()
      await store.dispatch(setMeXmppOnlineStatus(true))
      for (const msg of api.utils.internal_data.waitForNotifyPartiesPEP) {
        // eslint-disable-next-line no-await-in-loop
        await api.utils.notifyPartiesPEP(...msg)
        console.log(`sending delayed PEP: ${msg}`)
      }
      api.utils.internal_data.waitForNotifyPartiesPEP = []
      return {}
    })
    api.listen.on('reconnected', () => convrs.api.utils.registerHeadlineHandler())
    api.listen.on('disconnected', async () => {
      try {
        await store.dispatch(setMeXmppOnlineStatus(false))
        return {}
      } catch (err) {
        return console.error(err)
      }
    })

    api.listen.on('message', (msg) => {
      if (msg === undefined || msg === null
        || msg.chatbox === undefined || msg.chatbox === null) {
        return
      }

      const state = store.getState()
      if (!state.users.isOpenRightModal) {
        // show unread messages badge
        msg.chatbox.set('hidden', true)
      }

      convrs.api.utils.update_contact_last_message(msg.chatbox)
    })

    api.listen.on('sendMessage', (msg) => {
      if (msg === undefined || msg === null
        || msg.chatbox === undefined || msg.chatbox === null) {
        return
      }
      convrs.api.utils.update_contact_last_message(msg.chatbox)
    })

    api.listen.on('chatBoxInitialized', (chat) => convrs.api.utils.update_contact_last_message(chat))

    api.listen.on('MAMResult', (msg) => {
      convrs.api.utils.update_contact_last_message(msg.chatbox)
    })

    api.listen.on('getToolbarButtons', (element, buttons) => {
      setTimeout(() => {
        let collection = element.getElementsByTagName('converse-icon')
        Array.from(collection).forEach((e) => { e.size = '40px' })
      }, 50)
      return buttons
    })

    api.listen.on('controlBoxInitialized', async (elem) => {
      // roster view is ready to show
      elem.style.flex = 'unset'
      elem.style.maxWidth = 'unset'
    })

    api.listen.on('chatBoxViewInitialized', async () => {
      // chat panel is ready to show
      store.dispatch(setXmppChatPanelOpened(true))

      // bugfix for https://tree.taiga.io/project/connector-connector-web-app/task/1038
      setTimeout(() => {
        const elem = document.getElementsByTagName('converse-message-history')[0]
        // this is for mobile devices touch events
        elem?.addEventListener('touchmove', (event) => {
          event.stopPropagation()
          elem.scrollIntoView(false) // position to the bottom only once
          elem.addEventListener('touchmove', (e) => {
            // do not pass event to React
            e.stopPropagation()
          }, { passive: true })
        }, { once: true, passive: true })
        // similar as above, this is for desktops mouse wheel events
        elem?.addEventListener('wheel', (event) => {
          event.stopPropagation()
          elem.scrollIntoView(false)
          elem.addEventListener('wheel', (e) => {
            e.stopPropagation()
          }, { passive: true })
        }, { once: true, passive: true })
      }, 500)
    })

    api.listen.on('addClientFeatures', () => {
      api.disco.own.features.add('http://jabber.org/protocol/tune+notify')
      // https://xmpp.org/extensions/xep-0108.html
      api.disco.own.features.add('http://jabber.org/protocol/activity')
      api.disco.own.features.add('http://jabber.org/protocol/activity+notify')
    })

    // Triggered when window state has changed when user left the page and when came back
    api.listen.on('windowStateChanged', async (arg) => {
      let { state } = arg
      if (state === 'hidden') {
        // preparing for background, close Chact panel
        await convrs.api.utils.closeChatPanel()
        document.querySelector('svg[data-name="arrowLeft"]')?.parentNode.click()
      }
      return {}
    })
    // notifies about the roster receives a push event from server
    // (i.e. new entry in your contacts roster).
    api.listen.on('rosterPush', async (iq) => {
      const id = iq.querySelector('item').getAttribute('jid')
      const subscriptionType = iq.querySelector('item').getAttribute('subscription')

      if (subscriptionType === 'both') {
        const model = convrs.roster.models.find((e) => e.id === id)

        if (model) {
          convrs.api.utils.updateImageOnDom(model)
        }
      }

      convrs.api.utils.notify_unread()
      convrs.api.utils.closeChatPanel()
      console.log('Roster has been updated')
    })
    // eslint-disable-next-line no-undef
    const isCordova = process.env.REACT_APP_NODE_ENV === 'CORDOVA_APPLICATION' && cordova?.platformId === 'android'
    if (isMobile() || isCordova) {
      api.listen.on('afterFileUploaded', async (msg, attrs) => {
        await store.dispatch(endMobileImagePicker())
        await store.dispatch(hideModalSpinner())
        const jidTo = msg.get('jid')
        document.getElementsByClassName('chakra-image')[0].click()
        await api.chats.open(jidTo)
        const chat = await api.chats.get(jidTo)
        await chat?.sendMessage(attrs)
        // destroying progress indicator message placeholder, 'msg'
        setTimeout(() => msg.destroy(), 1000)
        store.dispatch(endMobileImageTransfer())
      })
      api.listen.on('messageInitialized', (model) => {
        const state = store.getState()
        if (state.chat.isMobileImagePickerState) {
          store.dispatch(beginMobileImageTransfer())
        }
      })
    }

    api.listen.on('beforeFileUpload', async (sender, file) => {
      /* eslint-disable */
      // https://web.archive.org/web/20131018091152/http://exif.org/Exif2-2.PDF
      //
      // SOI  Start of Image                  FFD8.H Start of compressed data
      // APP1 Application Segment 1           FFE1.H Exif attribute information
      // APP2 Application Segment 2           FFE2.H Exif extended data
      // DQT  Define Quantization Table       FFDB.H Quantization table definition
      // DHT  Define Huffman Table            FFC4.H Huffman table definition
      // DRI  Define Restart Interoperability FFDD.H Restart Interoperability definition
      // SOF  Start of Frame                  FFC0.H Parameter data relating to frame
      // SOS  Start of Scan                   FFDA.H Parameters relating to components
      // EOI  End of Image                    FFD9.H End of compressed data 
      const removeExifJpeg = (imageArrayBuffer, dv) => {
        let offset = 0
        let recess = 0
        let pieces = []
        let i = 0
        const markers = [0xffe1, 0xffe2, 0xffdb, 0xffc4, 0xffdd, 0xffc0]
        if (dv.getUint16(offset) === 0xffd8) {
          offset += 2
          let app1 = dv.getUint16(offset)
          offset += 2
          if (markers.indexOf(app1) === -1){
            return undefined
          }
          while (offset < dv.byteLength) {
            if (app1 === 0xffe1) {
              pieces[i] = { recess: recess, offset: offset - 2 }
              recess = offset + dv.getUint16(offset)
              i++
            }
            else if (app1 === 0xffda) {
              break
            }
            offset += dv.getUint16(offset)
            app1 = dv.getUint16(offset)
            offset += 2
          }
          if (pieces.length > 0) {
            let newPieces = []
            pieces.forEach((v) => {
              newPieces.push(imageArrayBuffer.slice(v.recess, v.offset))
            }, this)
            newPieces.push(imageArrayBuffer.slice(recess))
            return newPieces
          }
        }
      }
      /* eslint-enable */
      if (file.type === 'image/jpeg') {
        const buff = await file.arrayBuffer()
        let dataView = new DataView(buff)
        const bufArr = removeExifJpeg(buff, dataView)
        if (bufArr) {
          return new File(bufArr, file.name, { type: file.type })
        }
        return file
      }
      return file
    })
  }
}

export default CustomizationPlugin
